$base-color: #ce5b4a;
$base-color-light: lighten($base-color, 5);
$base-color-light2: lighten($base-color, 10);

$theme-colors: (
  "primary": $base-color,
);

$link-color: $base-color;

input:focus {
  outline: none !important;
  border:1px solid $base-color-light !important;
  box-shadow: 0 0 5px $base-color-light !important;
}

textarea:focus {
  outline: none !important;
  border:1px solid $base-color-light !important;
  box-shadow: 0 0 5px $base-color-light !important;
}

.swal2-styled.swal2-confirm {
  background-color: $base-color !important;
}

.swal2-styled.swal2-confirm:focus {
  outline: none !important;
  border:1px solid $base-color-light !important;
  box-shadow: 0 0 5px $base-color-light !important;
}

.bg-primary-light{
  background-color: $base-color-light !important;
}

.bg-primary-light:hover{
  background-color: darken($base-color-light, 10%) !important;
}

.bg-primary-light2{
  background-color: $base-color-light2 !important;
}

.bg-primary-light2:hover{
  background-color: darken($base-color-light2, 10%) !important;
}

.srg-paginacao .ngx-pagination .current {
  background: $base-color;
}

.mat-calendar-body-cell-content:hover, .mat-calendar-body-cell:hover > .mat-calendar-body-cell-content{
  background-color: lighten($base-color, 20) !important;
}

.mat-calendar-body-cell-content.mat-calendar-body-selected{
  background-color: $base-color !important;
}

.breadcrumb {
  padding: 0px !important;
  margin-bottom: 0px !important;
  background-color: transparent !important;
  color: $base-color !important;
}

.breadcrumb-item{
  cursor: pointer !important;
}

.breadcrumb > .active {
  cursor: default !important;
}